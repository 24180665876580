
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonText,
  IonToolbar,
} from '@ionic/react';
import { callOutline, camera, linkOutline, logoFacebook, logoInstagram, logoLinkedin, logoTwitter, logOutOutline, logoYoutube, mailOutline, videocam } from 'ionicons/icons';
import React, { useState } from 'react';
import FooterImage from '../components/FooterImage';

import Header from '../components/Header';
import { Api } from '../data/api';


const daktechLogo = "assets/resources/logo-daktech-blue.png";

const Daktech: React.FC<{ build: any, api: Api }> = ({ build, api }) => {
  return (
    <IonPage id="view-message-page">
      <Header api={api} title='DakTech Pty Ltd' />

      <IonContent fullscreen>
        <IonCard className="card-details">
          <IonCardHeader className='ion-text-center d-flex align-items-center'>
            <img src={daktechLogo} className='p-2' style={{ maxWidth: 300 }} />
            <IonText className='fst-italic'>Using technology to enhance and optimise your business processes</IonText>
          </IonCardHeader>
          <IonCardContent>
            <IonText className='ion-text-justify'>
              <p>
                DakTech believes in supporting and investing in the community.
                DakTech has been providing Stable on the Strand with IT Support and Software Development since 2009.</p>
              <p>
                DakTech provides affordable Software Development and IT Support to 150+ businesses throughout Australia.
                For trustworthy and reliable technology solutions contact DakTech today.
              </p>
              <p>
                Web App Created by DakTech
              </p>
            </IonText>


            <IonButton expand="block" fill="outline" target='blank' href="https://daktech.com.au" className="ion-margin-top ion-margin-horizontal">
              <IonIcon slot="start" icon={linkOutline}></IonIcon>Visit Website</IonButton>

            <IonButton expand="block" fill="outline" href="tel:0747230228" className="ion-margin-top ion-margin-horizontal">
              <IonIcon slot="start" icon={callOutline}></IonIcon>07 4723 0228</IonButton>

            <IonButton expand="block" fill="outline" href="mailto:support@daktech.com.au" className="ion-margin-top ion-margin-horizontal">
              <IonIcon slot="end" icon={mailOutline}></IonIcon>Contact Us</IonButton>

            <IonButtons className="ion-margin-vertical" style={{ display: 'flex', justifyContent: 'center' }}>
              <IonButton href='https://www.facebook.com/DakTechAustralia/' target='blank'>
                <IonIcon slot="icon-only" icon={logoFacebook} />
              </IonButton>
              <IonButton href='https://twitter.com/DaktechA' target='blank' >
                <IonIcon slot="icon-only" icon={logoTwitter} />
              </IonButton>

              <IonButton href='https://www.instagram.com/daktechaustralia/' target='blank' >
                <IonIcon slot="icon-only" icon={logoInstagram} />
              </IonButton>

              <IonButton href='https://www.youtube.com/channel/UCcpkSDVquIxqoeYpxsADziw/featured' target='blank' >
                <IonIcon slot="icon-only" icon={logoYoutube} />
              </IonButton>
              <IonButton href='https://www.linkedin.com/company/daktech-australia/mycompany/' target='blank' >
                <IonIcon slot="icon-only" icon={logoLinkedin} />
              </IonButton>

            </IonButtons>

            <IonText className="float-end" style={{fontSize: 12}}>Build: {build}</IonText>
          </IonCardContent>
        </IonCard>

      </IonContent>
      <IonFooter>
        <FooterImage />
      </IonFooter>
    </IonPage>
  );
}

export default Daktech;
