import { IonContent, 
  IonItem, 
  IonLabel, IonList, IonPage,  
  IonFooter, IonRouterLink, IonChip, IonIcon, IonItemDivider, IonButton } from '@ionic/react';
  
import React from 'react';

import {Api} from '../data/api'
import Moment from 'react-moment';
import User from '../model/User';
import FooterImage from '../components/FooterImage';
import Header from '../components/Header';
/*import QRCode from "react-qr-code";*/
import { call, chatboxEllipsesOutline, mail } from 'ionicons/icons';

const Current: React.FC<{api: Api, user: User, handleDismiss: ()=>void, doSignOut: (user: any)=>void, from : string}> = ({api, user, handleDismiss, doSignOut, from}) => {
  
  return (
    <IonPage>
      <Header api={api} title={`${user.firstname} ${user.surname}`} backCallback={handleDismiss}/>
      <IonContent fullscreen>
        <IonContent>
          <IonList>
          {user.email_address && user.email_address.length > 0 &&
            <IonItem>
              <IonLabel>Email</IonLabel>
                  <IonRouterLink href={`mailto:${user.email_address}`}>
                  <IonChip>
                      <IonIcon icon={mail} color="dark" />
                      <IonLabel>{user.email_address}</IonLabel>
                  </IonChip>
                  </IonRouterLink>
            </IonItem>
            }
            {user.contact_phone_1.length > 0 &&
            <IonItem>
                  <IonLabel>Phone 1</IonLabel>
                  <IonRouterLink href={`tel:${user.contact_phone_1}`}>
                  <IonChip>
                      <IonIcon icon={call} color="dark" />
                      <IonLabel>{user.contact_phone_1}</IonLabel>
                  </IonChip>
                  </IonRouterLink>
              </IonItem>}
              {user.contact_phone_2 && user.contact_phone_2.length > 0 &&
              <IonItem>
                  <IonLabel>Phone 2</IonLabel>
                  <IonRouterLink href={`tel:${user.contact_phone_2}`}>
                  <IonChip>
                      <IonIcon icon={call} color="dark" />
                      <IonLabel>{user.contact_phone_2}</IonLabel>
                  </IonChip>
                  </IonRouterLink>
              </IonItem>
              }
              {user.sms.length > 0 &&
              <IonItem>
                  <IonLabel>SMS</IonLabel>
                  <IonRouterLink href={`sms://${user.sms}`}>
                  <IonChip>
                      <IonIcon icon={chatboxEllipsesOutline} color="dark" />
                      <IonLabel>{user.sms}</IonLabel>
                  </IonChip>
                  </IonRouterLink>
              </IonItem>
              }
              <IonItemDivider>Emergency Contact</IonItemDivider>
              <IonItem>
                  <IonLabel>Name</IonLabel>
                  <IonLabel>{user.next_of_kin}</IonLabel>
              </IonItem>
              <IonItem>
                  <IonLabel>Relationship</IonLabel>
                  <IonLabel>{user.next_of_kin_relationship}</IonLabel>
              </IonItem>
              <IonItem>
                  <IonLabel>Phone</IonLabel>
                  <IonRouterLink href={`tel:${user.next_of_kin_phone}`}>
                  <IonChip>
                      <IonIcon icon={call} color="dark" />
                      <IonLabel>{user.next_of_kin_phone}</IonLabel>
                  </IonChip>
                  </IonRouterLink>
              </IonItem>
              
          </IonList>
          {(api.user?.group_id && api.user?.group_id > 2) && ((from == "Lookup" && user.latest && user.latest.type === 'In') || from == "Current") && /* Make sure above area leader */
            <div className='ion-text-center'> <IonButton  color="danger" onClick={() => doSignOut(user)}>Sign Out</IonButton>
            </div>
          }
        </IonContent>
      </IonContent>
      <IonFooter>
        <FooterImage />
      </IonFooter>
    </IonPage>
  );
};

export default Current;
