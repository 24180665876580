import {
  IonContent,
  IonRefresher, IonRefresherContent,
  IonHeader, IonSearchbar, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonList, IonPage, IonTitle, IonToolbar, useIonAlert, IonFooter, IonGrid, IonCol, IonRow, IonButton, useIonModal, IonSegment, IonSegmentButton, IonBadge, IonChip, IonIcon, IonRouterLink, IonListHeader, IonItemGroup, IonItemDivider, IonSelect, IonSelectOption, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonText
} from '@ionic/react';
import { RefresherEventDetail } from '@ionic/core';

import React, { RefObject, useEffect, useRef, useState } from 'react';

import { Api } from '../data/api'
import User, { Area, Job, Roster } from '../model/User';
import Moment from 'react-moment';
import FooterImage from '../components/FooterImage';
import Header from '../components/Header';

import UserProfile from './UserProfile';
import JobPage from './JobPage';
import { closeCircle } from 'ionicons/icons';
import { RouteComponentProps } from 'react-router';
import ProgressBar from 'react-bootstrap/ProgressBar';
import AnyJobPage from './AnyJobPage';
import { faBriefcase, faD, faLayerGroup, faMagnifyingGlassPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const JobListItem: React.FC<{ api: Api; job: Job; }> = ({ api, job }) => {

  const handleDismiss = () => {
    dismissDetails();
  };

  const [presentDetails, dismissDetails] = useIonModal(JobPage, {
    api,
    job,
    handleDismiss
  });

  const volsNeeded = job.volunteers_needed * job.shifts.length;

  const volsGot = api.jobshiftvols?.reduce((accumulator, currentValue) => {
    if (currentValue.job_id === job.id) return accumulator + currentValue.count;

    return accumulator;
  }, 0);

  const percentFull = (volsGot && volsNeeded > 0) ? Math.round((volsGot / volsNeeded) * 100) : 0;

  return (
    <>
      <IonItem lines='none' onClick={() => presentDetails()}>
        <IonLabel className='fw-bold m-0'><FontAwesomeIcon icon={faBriefcase} /> {job.title}</IonLabel>

      </IonItem>
      <div style={{ width: '100%' }} className='ps-1 pe-1'>
        <ProgressBar onClick={() => presentDetails()}>
          <ProgressBar now={percentFull} variant="success" />
          {percentFull < 50 && <ProgressBar now={100 - percentFull} variant="danger" />}
          {percentFull >= 50 && percentFull < 80 && <ProgressBar now={100 - percentFull} variant="warning" />}
          {percentFull >= 80 && percentFull < 100 && <ProgressBar now={100 - percentFull} variant="info" />}
        </ProgressBar>
      </div>
    </>)
}

const AreaListItem: React.FC<{ api: Api; area: Area; }> = ({ api, area }) => {

  const handleDismiss = () => {
    dismissDetails();
  };

  const [presentDetails, dismissDetails] = useIonModal(AnyJobPage, {
    api,
    area,
    handleDismiss
  });

  return <><IonItem onClick={() => presentDetails()}>
    <IonLabel>Any job in {area.title}</IonLabel>
  </IonItem></>
}

interface RosterListItemProps extends RouteComponentProps {
  api: Api;
  roster: Roster;
}
const RosterListItem: React.FC<RosterListItemProps> = ({ api, roster, history }) => {

  const start = new Date(roster.shift.start_time);
  const end = new Date(roster.shift.end_time);

  const sameday = start.toDateString() === end.toDateString();

  const hours = Math.abs(end.getTime() - start.getTime()) / (1000 * 60 * 60);

  return <IonCard
    onClick={(e) => {
      e.preventDefault();
      if (roster.job_id) {
        history.push(`/jobs/${roster.job_id}`);
      } else {
        history.push(`/areas/${roster.area_id}`);
      }

    }}
  >
    <IonCardHeader>
      <IonText className='fs-5 text-dark fw-bolder'>{roster.shift.title}</IonText>
      <IonCardSubtitle>{roster.area?.title} : {roster.job?.title ? roster.job?.title : 'Any job'}</IonCardSubtitle>
    </IonCardHeader>
    <IonCardContent>
      <div className='d-flex justify-content-between align-items-center fw-bold'>
        <p>
          <Moment format="ddd Do MMM h:mm a" utc>{roster.shift.start_time}</Moment>&nbsp;-&nbsp;
          {sameday ? <Moment format="h:mm a" utc>{roster.shift.end_time}</Moment> : <Moment format="ddd Do MMM h:mm a" utc>{roster.shift.end_time}</Moment>}
        </p>
        <IonBadge color="success" slot="end" >{hours} Hrs</IonBadge>
      </div>
        
    </IonCardContent>
  </IonCard>
}

interface RosterPageProps extends RouteComponentProps {
  api: Api;
}
const JobsPage: React.FC<RosterPageProps> = ({ api, history, location, match }) => {
  const [searchText, setSearchText] = useState('');
  const [tabSelected, setTab] = useState<string>('jobs');

  useEffect(() => {

    api.getJobs();

  }, []);

  return (
    <IonPage>
      <Header api={api} title='Roster' />
      <IonContent fullscreen>
        <IonHeader>
          <IonSegment onIonChange={(e: any) => e.detail.value && setTab(e.detail.value)} value={tabSelected}>
            <IonSegmentButton value="jobs">
              <IonLabel>Jobs</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="roster">
              <IonLabel>My Roster {api.volunteer?.rosters?.length ? <IonBadge color="success" slot='end'>{api.volunteer?.rosters?.length}</IonBadge> : ''}</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </IonHeader>
        <IonContent>

          {tabSelected === 'jobs' && <>
            <IonSearchbar
              value={searchText}
              onIonInput={e => setSearchText(e.detail.value!)}
              placeholder="Filter"
              showClearButton="always"
            ></IonSearchbar>
            <IonList>
              {api.areas && Array.from(api.areas.keys(), (area, key) => {

                return (<IonCard key={key} className='p-0'>
                  <IonCardHeader className="bg-info">

                    <IonLabel className='fs-5 fw-bolder text-dark'>
                      <FontAwesomeIcon icon={faLayerGroup} /> {area}
                    </IonLabel>
                  </IonCardHeader>
                  <IonCardContent className='p-1 mb-3'>
                    {api.areas?.get(area)?.map((job, index: number) => (searchText.length === 0 || job.area.title.toLowerCase().includes(searchText.toLowerCase())) && <AreaListItem key={index} api={api} area={job.area} />)[0]}
                    {api.areas?.get(area)?.map((job, index: number) => (searchText.length === 0 || job.title.toLowerCase().includes(searchText.toLowerCase())) && <JobListItem
                      key={index}
                      api={api}
                      job={job} />)}
                  </IonCardContent>

                </IonCard>)
              }
              )}

            </IonList></>
          }
          {tabSelected === 'roster' && <>
            {api.user && api.user.shared.length > 0 && <IonSelect interface="popover" onIonChange={(e) => api.setAltUser(e.detail.value)} placeholder={`${api.user.firstname} ${api.user.surname}`}>
              {api.user.shared.map((user, index) =>
                <IonSelectOption key={index} value={user.id}>{user.firstname} {user.surname}</IonSelectOption>
              )}</IonSelect>}
            <IonList>
              {api.volunteer?.rosters?.map((roster, index: number) => <RosterListItem
                key={index}
                history={history}
                location={location}
                match={match}
                api={api}
                roster={roster} />)}
            </IonList>
            {!api.volunteer?.rosters?.length && <IonCard>
              <IonCardContent className='p-0'>
                <IonGrid className='p-0'>
                  <IonRow>
                    <IonCol size='8' className='p-4'>
                      <IonText className='h3 fw-bolder text-danger'>No Rosters Yet!</IonText><br />
                      <IonText className='fs-6 fw-bold'>Create a Roster</IonText>
                    </IonCol>
                    <IonCol size='4' className='bg-success text-light d-flex align-items-center justify-content-center' onClick={() => setTab('jobs')}>
                      <FontAwesomeIcon fontSize="30" icon={faMagnifyingGlassPlus} />
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCardContent>
            </IonCard>}
          </>
          }
        </IonContent>
      </IonContent>
      <IonFooter>
        <FooterImage />
      </IonFooter>
    </IonPage>
  );
};

export default JobsPage;
