import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonPage, IonRow, IonText, IonTitle, IonToolbar, useIonAlert } from '@ionic/react';

import { Api } from '../data/api'
import React from 'react';
import Moment from 'react-moment';
import FooterImage from '../components/FooterImage';
import Header from '../components/Header';
import parseISO from 'date-fns/parseISO'
import { Event } from '../model/User';
import { logOutOutline, starOutline } from 'ionicons/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarPlus, faSignIn, faSignOut, faStar } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router';

const survey = 'https://www.surveymonkey.com/r/Stablevol24';

const EventCard: React.FC<{ api: Api; event: Event }> = ({ api, event }) => {
  const today = new Date();
  const commingUp = parseISO(event.event_start) > today;
  return <IonCard>
    <IonCardHeader>
      <IonCardTitle>
        {event.name}</IonCardTitle>
      <IonCardSubtitle>{commingUp && event.event_start && <>Main Event starts <Moment fromNow utc>{event.event_start}</Moment></>}</IonCardSubtitle>
    </IonCardHeader>

    <IonCardContent>
      <div dangerouslySetInnerHTML={{ __html: event.description }} />
    </IonCardContent>
  </IonCard>
}


const Signin: React.FC<{ api: Api }> = ({ api }) => {
  const [present] = useIonAlert();
  const history = useHistory();

  const onLog = async (type: string, user_id: number | undefined) => {

    if (user_id) {
      const responce = await api.log(type, user_id);
      if (responce.status !== 200) {
        //Only show on error
        present(responce.message, [{ text: 'Ok' }]);
      }


      return true;
    }

  };

  return (
    <IonPage>
      <Header api={api} title='Volunteer Sign In' />
      <IonContent fullscreen>
        <IonButton className='m-3 p-1' expand="block" style={{ height: 80 }} fill="solid" color="light" target='blank' href={survey}>
          <div className="d-flex flex-column">
            <div>
              <FontAwesomeIcon icon={faStar} shake />
              <FontAwesomeIcon icon={faStar} spin />
              <FontAwesomeIcon icon={faStar} bounce />

            </div>
            <IonText className='fw-bold p-1'>Let us know about your experience</IonText>
          </div>
        </IonButton>

        {!api.volunteer?.rosters?.length && <IonCard>
          <IonCardContent className='p-0'>
            <IonGrid className='p-0'>
              <IonRow>
                <IonCol size='8' className='p-4'>
                  <IonText className='h3 fw-bolder text-danger'>No Rosters Yet!</IonText><br />
                  <IonText className='fs-6 fw-bold'>Create a Roster</IonText>
                </IonCol>
                <IonCol size='4' className='bg-success text-light d-flex align-items-center justify-content-center' onClick={() => { 
                  // history.push('/signup') ;
                    present('Currently no jobs available, please check back soon!', [{ text: 'Ok' }]);

                  }}>
                  <FontAwesomeIcon fontSize="30" icon={faCalendarPlus} />
                </IonCol>
              </IonRow>
            </IonGrid>

          </IonCardContent>
        </IonCard>}

        {api.user && <IonCard>
          <IonCardContent className='p-0'>
            <IonGrid className='p-0'>
              <IonRow>
                <IonCol size='8' className='p-4'>
                  <IonText className='h3 fw-bolder text-primary'>{api.user.firstname} {api.user.surname}</IonText><br />
                  <IonText className='fs-6'>{api.user.latest ? <>Signed {api.user.latest.type} <Moment fromNow>{api.user.latest.modified && api.user.latest.modified.substr(0, 19)}</Moment></> : <>Never signed in</>}</IonText>
                </IonCol>
                <IonCol size='4' className={(api.user?.latest && api.user?.latest?.type === 'In' ? 'bg-danger': 'bg-secondary') + ' text-light d-flex align-items-center justify-content-center '} onClick={() => { api.user?.latest && api.user.latest.type === 'In' ? onLog('Out', api.user?.id): onLog('In', api.user?.id)}}>
                  {api.user?.latest && api.user.latest.type === 'In' ?
                    <FontAwesomeIcon fontSize="30" icon={faSignOut} /> :
                    <FontAwesomeIcon fontSize="30" icon={faSignIn} /> }
                </IonCol>
              </IonRow>
            </IonGrid>

          </IonCardContent>
        </IonCard>}

        {api.user && api.user.shared && api.user.shared.map((user, index) =>
            <IonCard key={index}>
            <IonCardContent className='p-0'>
              <IonGrid className='p-0'>
                <IonRow>
                  <IonCol size='8' className='p-4'>
                    <IonText className='h3 fw-bolder text-primary'>{user.firstname} {user.surname}</IonText><br />
                    <IonText className='fs-6'>{user.latest ? <>Signed {user.latest.type} <Moment fromNow>{user.latest.modified && user.latest.modified.substr(0, 19)}</Moment></> : <>Never signed in</>}</IonText>
                  </IonCol>
                  <IonCol size='4' className={(user.latest && user.latest.type === 'In' ? 'bg-danger': 'bg-secondary') + ' text-light d-flex align-items-center justify-content-center '} onClick={() => { user.latest && user.latest.type === 'In' ? onLog('Out', user?.id): onLog('In', user?.id)}}>
                    {user.latest && user.latest.type === 'In' ?
                      <FontAwesomeIcon fontSize="30" icon={faSignOut} /> :
                      <FontAwesomeIcon fontSize="30" icon={faSignIn} /> }
                  </IonCol>
                </IonRow>
              </IonGrid>

            </IonCardContent>
          </IonCard>
        )
        }

      </IonContent>
      <IonFooter>
        <FooterImage />
      </IonFooter>
    </IonPage>
  );
};

export default Signin;
