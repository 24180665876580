import { IonContent, 
  IonItem, 
  IonLabel, IonList, IonPage,  
  IonFooter, IonRouterLink, IonChip, IonIcon, IonItemDivider, IonCard, IonCardHeader, IonCardTitle, IonCardContent, useIonAlert, IonButton, IonBackButton, IonBadge } from '@ionic/react';
  
import React, { useEffect, useState } from 'react';

import {Api} from '../data/api'
import { Job, Roster, Shift } from '../model/User';
import FooterImage from '../components/FooterImage';
import Header from '../components/Header';
import Moment from 'react-moment';
import { RouteComponentProps } from 'react-router';
import JobPage from './JobPage';


interface JobDetailsPageProps
  extends RouteComponentProps<{
    id: string;
  }> {
    api: Api;
  }

const JobDetailsPage: React.FC<JobDetailsPageProps> = ({match, api, history}) => {
  const [theJob, setTheJob] = useState<Job>();

  const goBack = () => {history.go(-1)};

  useEffect(() => {
    console.log('page load');
    if(api.initalised && api.jobs.length === 0){
      api.getJobs();
    }

    if(api.jobs && !theJob){
      console.log('job set');
      setTheJob(api.jobs.find((el)=> el.id === parseInt(match.params.id)));
    }

  }, [api.initalised ,api.jobs, match.params.id]);

  return theJob ? <JobPage api={api} job={theJob} handleDismiss={goBack}/> : <></>;
};

export default JobDetailsPage;
