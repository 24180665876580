import { IonContent, IonButton, 
  IonPage, 
  IonInput,IonCard, 
  IonCardHeader,IonCardContent, useIonAlert, 
  IonLabel, IonCardSubtitle, 
  IonItem, IonSelect, IonSelectOption, IonFooter, IonIcon, IonList, IonText} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import '../theme/variables.css'

import {Api} from '../data/api'

import validator from 'validator';
import FooterImage from '../components/FooterImage';
import Header from '../components/Header';
import { personCircleOutline } from 'ionicons/icons';
import queryString from 'query-string';

/*
Status Codes - https://restfulapi.net/http-status-codes/
1xx: Informational – Communicates transfer protocol-level information.
2xx: Success – Indicates that the client’s request was accepted successfully.
3xx: Redirection – Indicates that the client must take some additional action in order to complete their request.
4xx: Client Error – This category of error status codes points the finger at clients.
5xx: Server Error – The server takes responsibility for these error status codes.

Looking for 200 OK

401 Unauthorized - Not signed in
403 Forbidden - Is singed in but no access to what is requested
404 Not Found
408 Request Timeout

*/

interface LoginPageProps extends RouteComponentProps { 
  api: Api
}
const LoginPage: React.FC<LoginPageProps> = ({ location, api }) => {
  const [present] = useIonAlert();

  const [email, setEmail] = useState("");
  const [sent, setSent] = useState(false);
  const [imageHeight, setImageHeight] = useState(100);
  const [code, setCode] = useState("");

  const [username, setUsername] = useState<string>("");

  const parsed : any = queryString.parse(location.search);

  useEffect(() => {
    if(!api.initalised) return;
    //returning to page after sending email
    if(api.emailToken){
      setSent(true);
    }

  }, [api.emailToken, api.initalised]);

  useEffect(() => {
    
    if(parsed.id && parsed.token && parsed.code){
      console.log('success');

      (async function inlineAsync() {
        console.log('Try send code');
        const response = await api.sendCode(parsed.code, {id:parsed.id, token: parsed.token, pass:''});
        if(!response){
          present('Code not valid, please try again.', [{ text: 'Ok' }]);
        }

    })();

    }
  }, []);

  const onSendEmail = async () => {

    if(!validator.isEmail(email)){
      present('Please enter an email', [{ text: 'Ok' }]);
      return;
    }

    const responce = await api.sendEmail(email, username);
    
    if(responce.status == 200){
      setSent(true);
      setUsername("");
    }else{
      present(responce.message, [{ text: 'Ok' }]);
    }

  };

  const setCardTitle = () => {

    if(sent === false && api.usernames.length > 0 ){
      return 'Select username'
    }else if (sent == false){
      return 'Enter your email address to get started.'
    } else {
      return 'Please check your email for the code'
    }
  }

  const setImage = () => {

    if(sent === false && api.usernames.length > 0 ){
      return <IonIcon icon={personCircleOutline} size='large' color='primary'/>
    }else if (sent == false){
      return <img src="/assets/Email_Icon.png" style={{width: 100}}/>
    } else {
      return <span style={{fontSize: 30, color: '#3880ff'}}>******</span>
    }
  }

  const onSubmit = async () => {

    if(!code){
      present('Please enter the code', [{ text: 'Ok' }]);
      return;
    }

    const responce = await api.sendCode(code, api.emailToken);
    if(!responce){
      present('Code not valid, please try again.', [{ text: 'Ok' }]);
    }

  };


  return (
    <IonPage>
      
      <IonContent fullscreen >
        <Header api={api} title='Welcome to VRS'/>
        <IonCard className="login-card">
          <IonCardHeader className="ion-text-center d-flex align-items-center">
            <IonText className="p-3">{setCardTitle()}</IonText>
            {setImage()}

          </IonCardHeader>
          <IonCardContent>
      <>
      {sent === false ? api.usernames.length > 0  ? (
        <>
        <IonList>
        {api.usernames.map((username, index)=>
        <IonButton key={index} fill="outline" className="login-button" expand="block" onClick={()=> api.setUsernames([])}>
          {username}
        </IonButton>
        )}
      </IonList>
      <IonButton fill="outline" className="login-button" expand="block" onClick={()=> api.setUsernames([])}>
        Not me, try again.
      </IonButton>
      </>
      ):
      (<>
      <IonItem className="ion-padding-bottom">
      <IonInput className="login-input" value={email} type="email" onIonFocus={()=> setImageHeight(20)}  placeholder="user@example.com" onIonInput={e => setEmail(e.detail.value!)} clearInput></IonInput>
      </IonItem>
      <IonButton 
        fill="outline" 
        expand="block" 
        disabled={!validator.isEmail(email)}
        style={{color: '#1450A0', borderStyle: 'none'}} 
        onClick={()=> onSendEmail()}>
          <b>Submit</b>
      </IonButton>
    </>
      ):(
        <>
        <h3>Code sent to email: {email}. Enter code below.</h3>
      <IonInput value={code} type="text" placeholder="Enter Code" onIonInput={e => setCode(e.detail.value!)} clearInput></IonInput>
      <IonButton 
        fill="outline" 
        className="login-button" 
        expand="block" 
        disabled={code.length !== 6}
        onClick={()=> onSubmit()}>
        Next
    </IonButton>
    <IonButton fill="outline" className="login-button" expand="block"  onClick={()=> setSent(false)}>
        Code didn't arrive, try again.
    </IonButton>
        </>
      )}
      </>
      
</IonCardContent>
        </IonCard>
      </IonContent>
      <IonFooter>
        <FooterImage />
      </IonFooter>
    </IonPage>
  );
};

export default LoginPage;
