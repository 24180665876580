
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonText,
  IonToolbar,
} from '@ionic/react';
import { callOutline, camera, linkOutline, logoFacebook, logoInstagram, logoLinkedin, logoTwitter, logOutOutline, logoYoutube, mailOutline, videocam } from 'ionicons/icons';
import React, { useRef, useState } from 'react';
import FooterImage from '../components/FooterImage';

import Header from '../components/Header';
import { Api } from '../data/api';


const Install: React.FC<{api: Api}> = ({api}) => {

  const url = window.location.href.replace('install','');

  const iOSCanInstall = 'standalone' in window.navigator;
  const iOSIsInstalled = window.matchMedia('(display-mode: standalone)').matches;
 
  function copyUrl() {
    
    navigator.clipboard.writeText(url);
  
  }

  return (
    <IonPage id="view-message-page">
      <Header api={api} title='How to Install'/>

      <IonContent fullscreen>
        <IonCard className="card-details">
          <IonCardHeader className='ion-text-center'>
            <IonCardTitle>IOS</IonCardTitle>
            <IonCardSubtitle>Install for easy access</IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent>
            <IonText>
              <ol>
                <li>Click the "Share" button on the bottom toolbar, then select the "Add to Home screen" option.
                <ul>
                <li>If you do not see the share button you will need to open this site in Safari</li>
                <li>Copy this link and open in safari</li>
                <li><input value={url} disabled></input><button onClick={()=>copyUrl()}>Copy text</button></li>
                </ul>
                </li>
                <li> Close this then open from the new link on your home screen</li>
                <li>Log in again (this will be the last time you have to login)</li>
              </ol>
          </IonText>
          <IonText>
            <h1>iOS and iPadOS storage isolation</h1>
          On iOS and iPadOS, there is no navigation or URL transfer between the browser and the installed icon. Even if it's the same PWA, every PWA icon that the user installs will have its own storage, isolated from Safari's tab and other icons. When a user opens the installed icon, no storage is shared with Safari. If your PWA needs a login, the user will need to log in again. If the app was added to the home screen several times, for each PWA instance, the user has a different session.
          </IonText>
          <IonText>
            <ul>
              <li>Can Install: {iOSCanInstall ? 'yes': 'no'}</li>
              <li>Is Installed: {iOSIsInstalled ? 'yes': 'no'}</li>
            </ul>
          </IonText>
          </IonCardContent>
        </IonCard>
        <IonCard className="card-details">
          <IonCardHeader className='ion-text-center'>
            <IonCardTitle>Android</IonCardTitle>
            <IonCardSubtitle>Install for easy access</IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent>
            <IonText>
              <ol>
                <li>The website should automatically prompt you to install when visiting this site. Just press install</li>
              </ol>
          </IonText>
          </IonCardContent>
        </IonCard>
      </IonContent>
      <IonFooter>
        <FooterImage />
      </IonFooter>
    </IonPage>
  );
}

export default Install;
