import { IonImg } from '@ionic/react';
import React, { Component }  from 'react';


const FooterImage: React.FC= () => {
  
  
  return (
        <IonImg style={{border: 'none'}} src="assets/img/shilouettew1024px.png" />
  
    );
};

export default FooterImage;
