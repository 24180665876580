import { IonButton,IonToolbar,IonTitle,IonButtons,IonBackButton,IonIcon, IonMenuButton } from '@ionic/react';
import React, { Component }  from 'react';

import { arrowBackOutline} from 'ionicons/icons';
import { Api } from '../data/api';


interface ContainerProps { 
  api: Api,
  title: string, 
  hideBack?: boolean,
  backCallback?: any,
}

const Header: React.FC<ContainerProps> = ({api, title, hideBack, backCallback}) => {
    
  return (
    <IonToolbar>
      <IonButtons  slot='start'>
        {(hideBack) ? '' : backCallback ? <IonButton color="default" onClick={backCallback} >
          <IonIcon slot="icon-only" icon={arrowBackOutline} /></IonButton> : 
          <IonBackButton />
        }
        
    </IonButtons>
    
    <IonTitle className='ion-text-center'><div className="fancy" style={{fontSize: '1.4rem'}}>{title}</div></IonTitle>

    <IonButtons  slot='end'>
      <IonMenuButton></IonMenuButton>
    </IonButtons>
    </IonToolbar>
  );
};

export default Header;
