import React from 'react';
import {  IonDatetime, IonInput, 
  IonItem, IonLabel, 
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonToggle} from '@ionic/react';
import { Controller } from 'react-hook-form';

interface InputProps {
    inputText: any,
    error: any,
    register: any,
    type: string,
    specForm?: any,
    placeholder?: string | undefined,
    list?: ListItem[] | undefined,
    dirty?:any;
  }

export interface CakeError {
    [key: string]: string;
  }

  export interface CakeErrors {
    [key: string]: CakeError[];
  }

export interface ReactFormError {
    message?: string;
    type: string;
  }


export interface ListItem {
    value: string;
    text: string;
  }  

export const CakeFormError: React.FC<{errors: CakeError | CakeError[] | undefined}> = ( {errors}) => {
    if(!errors) return <></>;

    const items = Array.isArray(errors) ? Object.entries(errors).map(([key,value],index)  =>  {
        return (
          <CakeFormError key={index} errors={value} />
        );
    }) :  Object.entries(errors).map(([key, value])  =>  {
        return (
          <IonItem key={key} color="invalid"><IonLabel>{value}</IonLabel></IonItem>
        );
    });
    
    return (<>
        {items}
    </>);
  }

export const ReactFormError: React.FC<{error?: ReactFormError}> = ({error}) => {
    if(!error) return <></>;
    return <IonItem color="invalid"><IonLabel>{error?.message ? error.message: error?.type}</IonLabel></IonItem>
  }

  interface SpecialInputProps {
    inputText: any,
    error: any,
    register: any,
    type: string,
    specForm?: any,
    control: any,
    field: any,
    dirty?:any;
  }

export const SpecialInput = ({inputText, control, field, error, register, specForm, type, dirty}: SpecialInputProps) => {
    
    return (
      <>
        <IonItem color={ error || specForm ? 'invalid' : dirty ? 'validate' : 'light'}>
              <IonLabel>{inputText}</IonLabel>
              <Controller
              control={control}
              name={field}
              {...register} 
              ref={null}
              render={({ field: { value, onChange }}) => (
                <IonToggle checked={value} onIonChange={e => onChange(e.detail.checked)} />
              )}
            />
        </IonItem>
        <ReactFormError error={error}/>
        <CakeFormError errors={specForm}/>
      </>
    );
  };

export const CustomInput = ({inputText, error, register, specForm, type, placeholder, list, dirty}: InputProps) => {
   
    const inputSelector = () => {
      if (type === 'datetime'){
        return <IonDatetime 
          className='color-black' 
          placeholder="Select Date" 
          displayFormat="MMM DD, YY h:mm a"
          {...register} 
          min={ `${new Date().getFullYear()}` } 
          max={ `${new Date().getFullYear() + 1}` } />
      }else if (type === 'date'){
        return <IonDatetime 
          className='color-black' 
          placeholder="Select Date" 
          {...register} 
          min={ `${new Date().getFullYear()}` } 
          max={ `${new Date().getFullYear() + 1}` } />
      } else if (type === "time") {
          return <IonDatetime placeholder="Select Time" displayFormat="HH:mm" {...register} />
      }else if (type === "text"){
          return <IonInput position="floating" color='dark' type="text" defaultValue="" placeholder={placeholder} {...register}></IonInput>
      }else if (type === "textarea"){
        return <IonTextarea position="floating" color='dark' defaultValue="" placeholder={placeholder} {...register}></IonTextarea>
    } else if (type === "tel"){
        return <IonInput defaultValue="" type="tel" placeholder={placeholder} {...register}></IonInput>
      } else if (type === "birthyear"){
        return <IonInput defaultValue="" type="number" 
        min='1900'
        max={`${new Date().getFullYear()}`}
        placeholder={placeholder} 
        {...register}></IonInput>
      } else if (type === "number"){
        return <IonInput defaultValue="" type="number" placeholder={placeholder} {...register}></IonInput>
      }else if (type === "email"){
        return <IonInput defaultValue="" type="email" placeholder={placeholder} {...register}></IonInput>
      }else if (type === "select" && list){
        return (<IonSelect {...register} >
              { list.map((option, index:number) => (<IonSelectOption key={index} value={option.value}>{option.text}</IonSelectOption>)) }
        </IonSelect>)
      }
    }  
    
    return (
      <>
        <IonItem color={ error || specForm ? 'invalid' : dirty ? 'validate' : 'light'}>
              <IonLabel position="stacked">{inputText}</IonLabel>
              {inputSelector()}
        </IonItem>
        <ReactFormError error={error}/>
        <CakeFormError errors={specForm}/>
      </>
    );
  };

export default CustomInput;